import { Box, Container, HStack, chakra, shouldForwardProp } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import React from 'react';


const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default function Animation() {
  return (
    <Container centerContent>
        <Container
        centerContent
        width='138px'
        height='200px'
        >
            <HStack>
                <Box>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="20px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#FFFFFF'
                        marginLeft='4px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.075,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="34px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#FCFCFC'
                        marginLeft='0px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.15,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="48px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#F9F9F9'
                        marginLeft='4px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.225,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="62px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#F6F6F6'
                        marginLeft='8px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.3,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="74px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#F2F2F2'
                        marginLeft='14px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.375,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="82px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#EEEEEE'
                        marginLeft='16px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.45,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="86px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#EAEAEA'
                        marginLeft='20px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.525,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="92px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#E7E7E7'
                        marginLeft='24px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.6,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="94px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#E4E4E4'
                        marginLeft='28px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.675,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="102px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#E0E0E0'
                        marginLeft='26px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.75,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="60px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#DDDDDD'
                        marginLeft='60px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.825,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="78px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#DADADA'
                        marginLeft='50px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.9,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="94px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D7D7D7'
                        marginLeft='38px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.975,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="98px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D5D5D5'
                        marginLeft='32px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.05,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="98px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D2D2D2'
                        marginLeft='30px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.125,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="94px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D0D0D0'
                        marginLeft='32px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.2,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="84px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#CDCDCD'
                        marginLeft='38px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.275,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="74px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#C9C9C9'
                        marginLeft='44px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.35,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="58px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#C6C6C6'
                        marginLeft='54px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.425,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="32px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#BFBFBF'
                        marginLeft='74px'
                        >
                    </ChakraBox>
                </Box>
                <Box>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="20px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#FFFFFF'
                        marginLeft='118px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.075,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="34px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#FCFCFC'
                        marginLeft='104px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.15,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="48px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#F9F9F9'
                        marginLeft='86px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.225,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="62px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#F6F6F6'
                        marginLeft='68px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.3,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="74px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#F2F2F2'
                        marginLeft='50px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.375,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="82px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#EEEEEE'
                        marginLeft='40px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.45,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="86px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#EAEAEA'
                        marginLeft='32px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.525,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="92px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#E7E7E7'
                        marginLeft='22px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.6,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="94px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#E4E4E4'
                        marginLeft='16px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.675,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="102px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#E0E0E0'
                        marginLeft='10px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.75,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="60px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#DDDDDD'
                        marginLeft='18px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.825,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="78px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#DADADA'
                        marginLeft='10px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.9,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="94px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D7D7D7'
                        marginLeft='6px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 0.975,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="98px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D5D5D5'
                        marginLeft='8px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.05,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="98px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D2D2D2'
                        marginLeft='10px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.125,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="94px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#D0D0D0'
                        marginLeft='12px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.2,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="84px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#CDCDCD'
                        marginLeft='16px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.275,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="74px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#C9C9C9'
                        marginLeft='20px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.35,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="58px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#C6C6C6'
                        marginLeft='26px'
                        >
                    </ChakraBox>
                    <ChakraBox
                        animate={{
                            opacity: [10,15,100],
                            rotateX: [90,360,360]
                        }}
                        // @ts-ignore no problem in operation, although type error appears.
                        transition={{
                        duration: 4,
                        delay: 1.425,
                        ease: "easeOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        }}
                        display="block"
                        width="32px"
                        height="10px"
                        borderRadius='20em'
                        boxShadow='dark-lg'
                        background='#BFBFBF'
                        marginLeft='32px'
                        >
                    </ChakraBox>
                </Box>
            </HStack>
            
            
            
        </Container>
      
    </Container>
  )
}   