import React from 'react';
import {
    theme,
    Heading,
    Text,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    bgGradient,
    size,
    Box,
    Container,
    Image,
  } from '@chakra-ui/react';
  import { MyTheme } from '../theme';

import ReactCardFlip from 'react-card-flip';
import { GiBrain } from 'react-icons/gi';

class LearningCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipSpeedBackToFront={2}
        flipSpeedFrontToBack={2}
      >
        <Card overflow='hidden' h='38vh' w={{base: '80vw',  sm: '40vw', md: '30vw', lg: '19vw'}} bgGradient="linear(to-br, #c94b4b, #4b134f)" onClick={this.handleClick} cursor='pointer' >
            <CardHeader paddingBottom={0}>
            <Heading color={'pink'} size='md'> Learning </Heading>
            </CardHeader>
            <CardBody h='33vh' w={{base: '80vw',  sm: '40vw', md: '30vw', lg: '19vw'}} >

                <Image border='2px' src='images/PassionsImages/hagey-hall.jpg' borderRadius='md' objectFit='cover' 
                 w='100%' h='100%' />

            </CardBody>
        </Card>

        <Card h='38vh' w={{base: '80vw',  sm: '40vw', md: '30vw', lg: '19vw'}}  onClick={this.handleClick} backgroundColor='blackAlpha.800' color='white' >
            <CardHeader>
            <Heading size='md'> Learning </Heading>
            </CardHeader>
            <CardBody h='33vh' w={{base: '80vw',  sm: '40vw', md: '30vw', lg: '19vw'}} overflow='hidden'>
              <Text fontSize={'sm'} >Some things I love learning about include design, business, psychology, philosophy, and physics (especially astrophysics and quantum physics).</Text>
            </CardBody>

        </Card>
      </ReactCardFlip>
    );
  }
}

export default LearningCard;