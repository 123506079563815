import React from 'react';
import {
  ChakraProvider,
  Container,
  Box,
  Text,
  Heading,
  VStack,
  Grid,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Divider,
  Link,
  Flex,
  Spacer,
  DarkMode,
  Image,
  Stack,
  HStack,
  GridItem,
  UnorderedList,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Badge,
  Center,
  Show,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Icon,
  Hide,
} from '@chakra-ui/react';
import { MyTheme } from './theme';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { AiTwotoneMail, AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import CommunityCard from './PassionCards/Community';
import ExpressionCard from './PassionCards/Expression';
import HealthCard from './PassionCards/Health';
import LearningCard from './PassionCards/Learning';
import NatureCard from './PassionCards/Nature';
import SpeakingCard from './PassionCards/Speaking';
import TeachingCard from './PassionCards/Teaching';
import Animation from './Animation';
import "@fontsource/lexend"; 
import ParticleBg from './ParticleBg';
import CountUp from 'react-countup';
import NavBar from './NavBar';

function App() {
  
  return (
    <ChakraProvider theme={MyTheme}>
      <DarkMode>
      <Box zIndex={'0'}>
      <ParticleBg />
      </Box>

      <Box textAlign="center" fontSize="xl" position='relative' zIndex={'2'} >
        <Container minH='100vh' minW='100vw' >
          <Box justifyContent={'right'} paddingTop={5}>
          <NavBar/>
          </Box>
          <Grid
            minH='100vh'
            minW='90vw'
            templateRows='repeat(3, 1fr)'
            templateColumns='repeat(7, 1fr)'
            gap={[2,2,4,4,4]}
            paddingTop={5}
            justifyContent={'center'}
          >
            <GridItem rowSpan={1} colSpan={5} rowStart={1} colStart={2} >
            <Heading padding={[2,10,10,10,10]} >
                  Hey, I'm Chaitya!
                </Heading>
              <Text fontSize={['lg','xl', '2xl', '2xl', '2xl']}>
                  Systems Design Engineering @ University of Waterloo
                </Text>
            </GridItem>
            <GridItem rowSpan={1} colSpan={5} rowStart={2} colStart={2} >
            <Animation />
            </GridItem>
            <GridItem rowSpan={1} colSpan={5} rowStart={3} colStart={2}  >
              <Text padding={[5,5,10,10,10]} fontSize={['md','xl', '2xl', '2xl', '2xl']} >
                  Empathize. Design. Grow. Product Management.
                </Text>
            </GridItem>
          </Grid>
         </Container>

         <Container minH='100vh' minW='100vw' centerContent id='about' overflow='hidden'>
              <Box padding={10}>
                <Heading fontSize={'xl'}>
                  About Me
                </Heading>
                <MeTab/>
              </Box>
         </Container>


       
        <Container minH='100vh' minW='100vw' centerContent id='experience'>
            <Box paddingTop={10} paddingBottom={5}>
            <Heading fontSize={'xl'}>
                My Experience
              </Heading>
            </Box>
            <Spacer/>
            <Box>
              <MiddleTab/>
            </Box>
            <Spacer/>
            
         </Container>

        <Container minH='100vh' minW='100vw' centerContent id='passions' >
            <Box paddingTop={10}>
              <Heading fontSize={'xl'}>
                My Passions
              </Heading>
              <Text as='i' fontSize='sm'>
                Click on a card to read more
              </Text>
            </Box>
            <Spacer/>
            <Box>
              <CardGrid/>
            </Box>
            <Box padding={5}>
              
            </Box>
         </Container>

        
        <Divider/>

 
        <Container minH='10vh' minW='100vw' id='contact'>
          <Text padding={10} >Connect with Me!</Text>
            <PageFooter />
         </Container>
 
      </Box>
      </DarkMode>
    </ChakraProvider>
    
  );
}


function MeTab(){
  return (
    <Grid
    minH='75vh'
    minW='85vw'
    templateRows='repeat(7, 1fr)'
    templateColumns='repeat(5, 1fr)'
    gap={4}
    display={{ md: 'grid', lg: 'grid'}}
    paddingTop={5}
    justifyContent={'center'}
  >
    <GridItem rowSpan={1} colSpan={5} rowStart={1}>
    <Stack direction='row' spacing={1} flexWrap='wrap' display={{md: 'run-in'}} padding={5} >
          <Badge colorScheme='black' variant='solid'> Database Management Systems</Badge>
          <Badge colorScheme='black' variant='solid'> Cloud</Badge>
          <Badge colorScheme='black' variant='solid'>Smart Applications</Badge>
          <Badge colorScheme='black' variant='solid'>DevOps</Badge>
          <Badge colorScheme='black' variant='solid'>Cybersecurity</Badge>
          <Badge colorScheme='black' variant='solid'> Web</Badge>
          <Badge colorScheme='black' variant='solid'> Mobile</Badge>
          <Badge colorScheme='black' variant='solid'>On-premise</Badge>
          <Badge colorScheme='black' variant='solid'> Consumer Applications</Badge>
          <Badge colorScheme='black' variant='solid'> Enterprise Applications</Badge>
        </Stack>
    </GridItem>
    <GridItem rowSpan={4} colSpan={2} rowStart={2}>
      <Center>
      <Box flexShrink={2} >
        <Image border='2px' src='images/chaitya-patel-profile.jpeg' borderRadius='md' objectFit='cover'
         boxSize={{base: '3xs', sm: '3xs', md: 'sm', lg: 'md', xl: 'md'}} />
      </Box>
      </Center>
    </GridItem>
    
    <GridItem rowSpan={4} colSpan={3} rowStart={2} colStart={3}>

      <Text padding='5' paddingTop={{base: 5}} align='left' fontSize={{base: 'sm', sm: 'sm', md: 'md', lg: 'lg'}} overflowY='visible' >
        Ever since I discovered what product management was in Grade 12, I knew it was the path I wanted to go down. 
        Now having completed 4 internships in product management and design, I have no doubt that I made the right choice. 
        <br/><br/>
        Being in a co-op program where I get the chance to complete 6 internships, my strategy was to use each opportunity 
        to experience product management in each part of the product lifecycle while also learning about how the discipline 
        differs at small, medium, and large companies. 
        <br/><br/>
        After completing 3 product interships that aligned with my strategy
        I am interested in interning at a company where the culture and product area are the right fit for me in the long-term.
        <br/><br/>
        </Text>

    </GridItem>
    <GridItem rowSpan={2} colSpan={5} rowStart={6}  >
    <Show above='md'>
    <Stack direction='row' padding={10} display={'flex'} >
      <Stat>
            <StatLabel>Products worked on</StatLabel>
            <StatNumber><CountUp end={9} enableScrollSpy='true' /></StatNumber>
        </Stat>
        <Stat >
            <StatLabel>Features defined</StatLabel>
            <StatNumber><CountUp end={7} enableScrollSpy='true' /></StatNumber>
        </Stat>
        <Stat >
            <StatLabel>Tutorials & Demos</StatLabel>
            <StatNumber><CountUp end={8} enableScrollSpy='true' /></StatNumber>
        </Stat>
        <Stat >
            <StatLabel>Features designed</StatLabel>
            <StatNumber><CountUp end={15} enableScrollSpy='true' />+</StatNumber>
        </Stat>
    </Stack>
    </Show>
    <Show below='md'>
    <Stack direction='row' paddingTop={5} paddingRight={2} paddingLeft={2}   display={'flex'} >
      <Stat>
            <StatLabel>Products<br/>worked on</StatLabel>
            <StatNumber><CountUp end={9} enableScrollSpy='true' /></StatNumber>
        </Stat>
        <Stat >
            <StatLabel>Features<br/>defined</StatLabel>
            <StatNumber><CountUp end={7} enableScrollSpy='true' /></StatNumber>
        </Stat>
        <Stat >
            <StatLabel>Tutorials<br/>& Demos</StatLabel>
            <StatNumber><CountUp end={8} enableScrollSpy='true' /></StatNumber>
        </Stat>
        <Stat >
            <StatLabel>Features<br/>designed</StatLabel>
            <StatNumber><CountUp end={15} enableScrollSpy='true' />+</StatNumber>
        </Stat>
    </Stack>
    </Show>
    </GridItem>
   
  </Grid>
  )
}

function MiddleTab() {
  return (
    <Tabs size='sm' variant='soft-rounded' colorScheme='coral' color={useColorModeValue('red.100')} >
  <TabList>
   <Tab color={'gray.200'}  >SAP</Tab>
    <Tab color={'gray.200'} >ThoughtWire</Tab>
    <Show above='base' >
    <Tab color={'gray.200'} >Wind River: PM</Tab>
    <Tab color={'gray.200'} >Wind River: UX</Tab>
    </Show>
    <Show below='base'>
    <Tab color={'gray.200'} >WR: PM</Tab>
    <Tab color={'gray.200'} >WR: UX</Tab>
    </Show>
    
  </TabList>
  <TabPanels>
    <TabPanel>
      <TabBox1/>
    </TabPanel>
    <TabPanel>
      <TabBox2/>
    </TabPanel>
    <TabPanel>
      <TabBox3/>
    </TabPanel>
    <TabPanel>
      <TabBox4/>
    </TabPanel>
  </TabPanels>
</Tabs>
  )
}

function TabBox1() {
  return (
    <Card h='75vh' w='85vw' overflow='scroll' backgroundColor='blackAlpha.800' color='white'>
      <CardHeader height='15%'>
      <Flex>
          <Box p='5'>
            <Heading paddingTop='5' size='md'> PM @ SAP </Heading>
          </Box>
          <Spacer/>
          <Box p='5'>
            <Image
                src='https://www.sap.com/dam/application/shared/logos/sap_logo_rgb_onwhite_0300_0300.png.adapt.png/1656382976488.png'
                boxSize='80px'
                objectFit='contain'
                />
          </Box>
        </Flex>
      </CardHeader>
      <CardBody height='85%'>
        <Grid
        templateRows='repeat(5, 1fr)'
        templateColumns='repeat(9, 1fr)'
        display={{ md: 'grid', lg: 'grid'}}
        >
          <GridItem rowSpan={5} colSpan={5} colStart={1}  >
            <Text fontSize='sm' align='left' padding={5}>
            Over the course of the term, I worked on a project to internally adopt Application Insights (Microsoft Azure Monitor) as the  primary source of data collection and analytics for SAP HANA Cloud. With a fellow intern, 
            I conducted pilot testing of the tool by creating sample reports and dashboards, defined data requirements based on business needs, and presented the capabilities of the tool to leadership for approval.
            Additionally, I partnered with a colleague to conduct market research on Cloud Database Intelligent Optimization to aid the global SAP HANA Cloud Product Management team in planning for new features.
            <br/> <br/> I also directly interacted with customers by responding to their feature requests, as well as publishing multiple tutorials and  demoing several features internally and externally to over 300 customers. 
            <br/> <br/>Features I demoed and wrote tutorials for include: <br/>
                <UnorderedList>
                  <ListItem>SAP HANA database explorer Visual Studio Code extension</ListItem>
                  <ListItem>Go Driver for SAP HANA Cloud, data lake</ListItem>
                  <ListItem>.NET Entity Framework Core for SAP HANA</ListItem>
                  <ListItem>Typescript support for SAP HANA</ListItem>
                </UnorderedList> <br/>

            Through this, SAP taught me about the importance of providing teaching materials to ease feature adoption as well as how vital it is to maintain company culture especially with such global impact. 
          </Text>
          </GridItem>
          <GridItem rowSpan={5} colSpan={1} colStart={6} >
            <Show above='md'>
              <Center>
            <Box h='400px'>
              <Divider orientation='vertical'/>
            </Box>
            </Center>
            </Show>
            <Show below='md'>
              <Center>
                <Divider/>
              </Center>
            </Show>
          </GridItem>
          
          <GridItem rowSpan={5} colSpan={3} colStart={7} >
          <VStack padding={5}>
            <Text fontSize='lg' as='b' >
              Published Tutorials and Demos
            </Text>
            <Link fontSize='sm' href='https://developers.sap.com/tutorials/hana-cloud-dl-clients-golang.html' isExternal>
            Connect to Data Lake Relational Engine Using the Go Driver <ExternalLinkIcon mx='2px' />
            </Link>
            <Link fontSize='sm' href='https://developers.sap.com/tutorials/hana-dbx-extension.html' isExternal>
            Use the SAP HANA Database Explorer Extension <ExternalLinkIcon mx='2px' />
            </Link>
            <Link fontSize='sm' href='https://blogs.sap.com/2023/03/30/whats-new-in-sap-hana-cloud-march-2023/#:~:text=ADD%20column-,SAP%20HANA%20Database%20Clients,-Next%20on%20the' isExternal>
            What’s New in SAP HANA Cloud: SAP HANA Client Demos  <ExternalLinkIcon mx='2px' />
            </Link>
            <br/>
            <Image 

              src='images/SAP Developer Center.png'
              border='1px'
              objectFit={'cover'}
              borderColor='gray.500'
            
            />
            </VStack>
          </GridItem>
        </Grid>
      </CardBody>
  </Card>
  )
}

function TabBox2() {
  return (
    <Card h='75vh' w='85vw' overflow='scroll' backgroundColor='blackAlpha.800' color='white' >
      <CardHeader height='15%'>
        <Flex>
          <Box p='5'>
            <Heading paddingTop='5' size='md'> PM @ ThoughtWire </Heading>
          </Box>
          <Spacer/>
          <Box p='5'>
            <Image
            src='https://media.licdn.com/dms/image/C4E0BAQF-7tN8xbYS2g/company-logo_200_200/0/1627584351264?e=2147483647&v=beta&t=m9OUedxw6NLxCEHhgpViM_tmc5mY22akBwHimKIb9Ws'
            objectFit='contain'
            boxSize='60px'
            />
          </Box>
        </Flex>
      </CardHeader>
      <CardBody height='85%'>
      <Grid
        templateRows='repeat(5, 1fr)'
        templateColumns='repeat(9, 1fr)'
        display={{ md: 'grid', lg: 'grid'}}
        >
          <GridItem rowSpan={5} colSpan={5} colStart={1}  >
          <Text fontSize='sm' align='left' padding={5} >ThoughtWire uses Digital Twin technology to operationalize data from built spaces such as office buildings and hospitals to ease interaction between building administrators, users and the building itself with mobile and web applications. <br/>

            <br/>Features I defined included:
            <UnorderedList>
              <ListItem>A building health module that allows building administrators to visualize system status on a digitized floor map with the ability to take suggested actions to resolve these issues as well as real-time insights into the utilization of the building such as occupancy levels, comfort levels, sustainability levels, and productivity levels in different zones of the building.</ListItem>
              <ListItem>Setting building automations.</ListItem>
              <ListItem>Triggering emergency notifications from the web app to the mobile app.</ListItem>
              <ListItem>Submitting work orders through the mobile app and managing them through the web app.</ListItem>
            </UnorderedList>
            <br/>

            Being at a smaller company, my responsibilities went beyond product management and expanded into other areas including project and program management. As a side project, I set up the process to capture product improvement requests from internal and external stakeholders in a way that made them more actionable. By defining a process and adding automation to parts of the planning process, I helped increase efficiency of the product pipeline and reduced variation in the quality of product requirements and documentation created.  
            </Text>
          </GridItem>
          <GridItem rowSpan={5} colSpan={1} colStart={6} >
            <Show above='md'>
              <Center>
            <Box h='400px'>
              <Divider orientation='vertical'/>
            </Box>
            </Center>
            </Show>
            <Show below='md'>
              <Center>
                <Divider/>
              </Center>
            </Show>
          </GridItem>
          
          <GridItem rowSpan={4} colSpan={3} rowStart={2} colStart={7}  >
          <VStack padding={5}>
            <Text fontSize='lg' as='b' >
              ThoughtWire Product Process
            </Text>
              <TWModal/>
            </VStack>
          </GridItem>
        </Grid>
      </CardBody>
  </Card>
  )
}
function TabBox3() {
  return (
    <Card h='75vh' w='85vw' overflow='scroll' backgroundColor='blackAlpha.800' color='white'>
      <CardHeader height='15%'>
      <Flex>
          <Box p='5'>
            <Heading paddingTop='5' size='md'> PM @ Wind River</Heading>
          </Box>
          <Spacer/>
          <Box p='5'>
            <Image
            src='https://media.licdn.com/dms/image/C4E0BAQGLmgBBcRhoSw/company-logo_200_200/0/1611721946459?e=2147483647&v=beta&t=1uXJNkQKBykICd1kh25Wvi-zXvLo9PGL0hQZQrbkq00'
            boxSize='60px'
            objectFit='contain'
            />
          </Box>
        </Flex>
      </CardHeader>
      <CardBody height='85%'>
      <Grid
        templateRows='repeat(5, 1fr)'
        templateColumns='repeat(9, 1fr)'
        display={{ md: 'grid', lg: 'grid'}}
        >
          <GridItem rowSpan={5} colSpan={5} colStart={1}  >
          <Text fontSize='sm' align='left' padding={5} > This being my second co-op term at Wind River, I had a good working knowledge of the product to the extent that I was able to become productive right away. 
            
            <br/> <br/> Products and features that I defined included: <br/>

              <UnorderedList>
                <ListItem>Creating and managing user groups, roles, and permissions (Identity and Access Management)</ListItem>
                <ListItem>Command Line Interface Tool for Wind River Studio</ListItem>
                <ListItem>System Configurator: A tool that allows the user to graphically define a system project with components including a board, OS, virtual machines, containers, hypervisors, etc. An example would be a Linux assembly with third-party and Wind River supplied packages, policies, and actions.</ListItem>
              </UnorderedList>
              <br/>
              Having previously worked with the UX Design team gave me an extremely valuable and unique perspective on product management and how it interacts with different teams. I am grateful that this was my first product management experience because it gave me a foundation that is hard to replicate, from what I value about product management to seeing what skills a great product management team must possess. 
             
            </Text>
          </GridItem>
          <GridItem rowSpan={5} colSpan={1} colStart={6} >
            <Show above='md'>
              <Center>
            <Box h='400px'>
              <Divider orientation='vertical'/>
            </Box>
            </Center>
            </Show>
            <Show below='md'>
              <Center>
                <Divider/>
              </Center>
            </Show>
          </GridItem>
          
          <GridItem rowSpan={5} colSpan={3} colStart={7}  >
          <VStack padding={5}>
            <Text fontSize='lg' as='b' >
              Related Content
            </Text>
            <WRPMModal/>
            <Spacer/>
            </VStack>
          </GridItem>
        </Grid>
      </CardBody>

  </Card>
  )
}

function TabBox4() {
  return (
    <Card h='75vh' w='85vw' overflow='scroll' backgroundColor='blackAlpha.800' color='white' >
      <CardHeader height='15%'>
      <Flex>
          <Box p='5'>
            <Heading paddingTop='5' size='md'> UX Design @ Wind River </Heading>
          </Box>
          <Spacer/>
          <Box p='5'>
            <Image
            src='https://media.licdn.com/dms/image/C4E0BAQGLmgBBcRhoSw/company-logo_200_200/0/1611721946459?e=2147483647&v=beta&t=1uXJNkQKBykICd1kh25Wvi-zXvLo9PGL0hQZQrbkq00'
            boxSize='60px'
            objectFit='contain'
            />
          </Box>
        </Flex>
      </CardHeader>
      <CardBody height='85%'>
      <Grid
        templateRows='repeat(5, 1fr)'
        templateColumns='repeat(9, 1fr)'
        display={{ md: 'grid', lg: 'grid'}}
        >
          <GridItem rowSpan={5} colSpan={5} colStart={1}  >
          <Text fontSize='sm' align='left' padding={5} >
            Working as a user experience designer was a fun and impactful experience. I enjoyed the role that creativity played and how every feature I was assigned to design felt like a puzzle, but with many possible right answers. I got to witness iterative design firsthand, and it was amazing to see how designs would evolve after rounds of giving, receiving, and applying feedback. 
            <br/> <br/> As part of Wind River Studio, I particularly worked on a tool called Pipeline Manager which allows users to build and run pipelines using blocks (e.g., code, Linux images, VxWorks projects, QA tests etc.). 
            <br/> <br/> Some features that I designed and improved included: <br/>

              <UnorderedList>
                <ListItem>Editing pipeline block configuration</ListItem>
                <ListItem>Rearranging pipeline blocks</ListItem>
                <ListItem>Versioning</ListItem>
                <ListItem>Running pipelines and viewing status</ListItem>
              </UnorderedList>
              <br/> If you would like to see some of my designs, view the presentation.

            </Text>
          </GridItem>
          <GridItem rowSpan={5} colSpan={1} colStart={6} >
            <Show above='md'>
              <Center>
            <Box h='400px'>
              <Divider orientation='vertical'/>
            </Box>
            </Center>
            </Show>
            <Show below='md'>
              <Center>
                <Divider/>
              </Center>
            </Show>
          </GridItem>
          
          <GridItem rowSpan={5} colSpan={3} colStart={7} >
          <VStack padding={5} >
            <Text fontSize='lg' as='b' >
              Related Content
            </Text>
            <Link fontSize='sm' href='https://www.forbes.com/sites/windriver/2022/07/12/wind-river-technology-helps-the-nasa-james-webb-space-telescope-brave-extremes-to-bring-home-treasure/?sh=3407ea8feff7' isExternal>
            Wind River Technology on NASA James Webb Telescope  <ExternalLinkIcon mx='2px' />
            </Link>
            <Link fontSize='sm' href='https://www.windriver.com/inspace' isExternal>
            Read about Wind River in Space  <ExternalLinkIcon mx='2px' />
            </Link>
            <WRUXModal/>
            <Spacer/>
            </VStack>
          </GridItem>
        </Grid>
      </CardBody>

  </Card>
  )
}

function CardGrid() {
  return (
    <Grid
    minH='75vh'
    minW='85vw'
    templateRows='repeat(2, 1fr)'
    templateColumns='repeat(8, 1fr)'
    gap={4}
    display={'flex'}
    flexWrap={'wrap'}
    justifyContent={'center'}
    alignContent='center'
  >
    <GridItem rowSpan={1} colSpan={2} rowStart={1} colStart={1}  ><LearningCard/></GridItem>
    <GridItem rowSpan={1} colSpan={2} rowStart={1} colStart={3}  ><HealthCard/></GridItem>
    <GridItem rowSpan={1} colSpan={2} rowStart={1} colStart={5}  ><SpeakingCard/></GridItem>
    <GridItem rowSpan={1} colSpan={2} rowStart={1} colStart={7}  ><NatureCard/></GridItem>
    <GridItem rowSpan={1} colSpan={2} rowStart={2} colStart={2}  ><ExpressionCard/></GridItem>
    <GridItem rowSpan={1} colSpan={2} rowStart={2} colStart={4}  ><CommunityCard/></GridItem>
    <GridItem rowSpan={1} colSpan={2} rowStart={2} colStart={6}  ><TeachingCard/></GridItem>
   
  </Grid>
  )
}

function Github() {
  return (
  <Link href='https://github.com/chaitya-patel' isExternal>
    <Icon as={AiFillGithub} boxSize={7} />
  </Link>
  )
}

function LinkedIn() {
  return (
  <Link href='https://www.linkedin.com/in/chaitya-patel/' isExternal>
    <Icon as={AiFillLinkedin} boxSize={7}/>
  </Link>
  )
}

function Outlook() {
  return (
    <Link href='mailto:chaitya.patel@uwaterloo.ca' >
      <Icon as={AiTwotoneMail} boxSize={7} />
    </Link>
  )
}

function PageFooter() {
  return (
    <Container w='250px' paddingBottom={10}>
      <Flex>
        <Outlook />
        <Spacer />
        <LinkedIn/>
        <Spacer />
        <Github/>
  </Flex>
    </Container>
  )
}

function TWModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Image onClick={onOpen} cursor='pointer'

        src='images/TWProductProcess.png'
        border='1px'
        objectFit={'cover'}
        borderColor='gray.500'
      
      />

      <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ThoughtWire Product Process</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Image

            src='images/TWProductProcess.png'
            border='1px'
            objectFit={'cover'}
            borderColor='gray.500'

            />
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function WRPMModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Image onClick={onOpen} cursor='pointer'

        src='images/Wind River PM Project.png'
        border='1px'
        objectFit={'cover'}
        borderColor='gray.500'
      
      />

      <Button onClick={onOpen} cursor='pointer'> View Presentation </Button>

      <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio justifyContent={'center'} ratio={16 / 9}>
              <iframe
              title="intern presentation"
              src="https://docs.google.com/presentation/d/e/2PACX-1vTCXwIJNHkX9BHQZh2-uAYgi7tLaJTg1janxgb0dhIzOF-EJ-b5LYaHlAP9sBdK-A/embed?start=false&loop=false&delayms=3000" 
              allowFullScreen
              />
            </AspectRatio>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function WRUXModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Image onClick={onOpen} cursor='pointer'

        src='images/Wind River UX Pipeline Manager.png'
        border='1px'
        objectFit={'cover'}
        borderColor='gray.500'
      
      />

      <Button onClick={onOpen} cursor='pointer'> View Presentation </Button>

      <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <AspectRatio justifyContent={'center'} ratio={16 / 9}>
              <iframe
              title="intern presentation"
              src="https://docs.google.com/presentation/d/e/2PACX-1vSiQ1WwODp-AaTD4QLiEFiL_Aw9nc7Aift-sMLk6aqT25s0jABLb1LAAZQ_k2T20A/embed?start=false&loop=false&delayms=3000" 
              allowFullScreen
              />
            </AspectRatio>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}



export default App;