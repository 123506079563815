import React from "react";
import { Button } from "@chakra-ui/react";

export function ScrollAboutMe() {
  const handleClickScroll = () => {
    const element = document.getElementById('about');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Button  variant={'link'} onClick={handleClickScroll}>About Me</Button>
    </>
  );
}

export function ScrollExperience() {
    const handleClickScroll = () => {
      const element = document.getElementById('experience');
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <>
        <Button variant={'link'} onClick={handleClickScroll}>Experience</Button>
      </>
    );
  }


  export function ScrollPassions() {
    const handleClickScroll = () => {
      const element = document.getElementById('passions');
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <>
        <Button variant={'link'} onClick={handleClickScroll}>Passions</Button>
      </>
    );
  }

  export function ScrollContact() {
    const handleClickScroll = () => {
      const element = document.getElementById('contact');
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <>
        <Button variant={'link'} onClick={handleClickScroll}>Connect</Button>
      </>
    );
  }
  