import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  useDisclosure,
  Stack,
  Link,
  Text,
  Heading,
  Button
} from "@chakra-ui/react";
import { ScrollAboutMe, ScrollExperience, ScrollPassions, ScrollContact } from "./ScrollButtons";

export default function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="NavBar">
      <Flex
        py={2}
        px={4}
        direction={["column", "row"]}
        justify="space-between"
      >
        <Flex alignItems="center" wrap="wrap">
          <Flex flexGrow={1} justify="center" /*border='1px'*/>
            {/* <Heading ml={[4, 0]}>Zensurance</Heading> */}
            
          </Flex>
          <HamburgerIcon
            onClick={isOpen ? onClose : onOpen}
            display={["inline", "none"]}
            // border='1px'
          />
        </Flex>
        <Flex
            
          display={[isOpen ? "flex" : "none", "flex"]}
          
        >
          <Stack align="center" spacing={10} direction={["column", "row"]}>
            <ScrollAboutMe/>
            <ScrollExperience/>
            <ScrollPassions/>
            <ScrollContact/>
            <Button variant={'link'} onClick={()=> window.open("resume-chaityapatel.pdf", "_blank")} >Resume</Button>
          </Stack>
        </Flex>
      </Flex>
    </div>
  );
}
